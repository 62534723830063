import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Event, Props as EventProps } from './Event'

export interface Props {
  events: EventProps[]
}

export const EventsList = memo(function EventsList({ events }: Props) {
  if (events.length < 1) {
    return null
  }

  return (
    <Container>
      {events
        .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
        .map((item, index) => (
          <Event key={index} {...item} />
        ))}
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem auto;
  padding: 0 15.5vw;

  @media (max-width: 1399px) {
    padding-right: 1.9375rem;
    padding-left: 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
  }
`

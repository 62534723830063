import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  date?: string
  dateCreated: string
  image?: ImageProps
  languageCode: string
  title?: string
  URL: string
}

export const Event = memo(function Event({
  date,
  image,
  languageCode,
  title,
  URL,
}: Props) {
  const [ref, isVisible] = useInView()
  const variants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  }

  return (
    <Container to={URL}>
      <motion.div
        ref={ref}
        animate={isVisible ? 'visible' : 'hidden'}
        variants={variants}
      >
        <Background>
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
        </Background>
        <Wrapper dial={4}>
          {title ? <Title>{title}</Title> : null}
          {date ? <Date>{date}</Date> : null}
          <CTA
            label={useVocabularyData('discover-details', languageCode)}
            noActions
            variant="corners"
          />
        </Wrapper>
      </motion.div>
    </Container>
  )
})

const Container = styled(Link)`
  display: block;
  margin-top: 50px;

  &:first-of-type {
    margin-top: 0;
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    height: 100%;
    position: relative;
    transition: 0.2s ease-in-out;
  }
`

const Background = styled.div`
  width: 50%;
  overflow: hidden;
  padding-bottom: 33%;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.2s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 70%;
  }
`

const Wrapper = styled(FlexBox)`
  width: 50%;
  background: #edf4f1;
  padding: 6.25rem 7.292vw;
  padding-left: 4.375rem;
  text-align: left;

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 3.75rem 1.9375rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 1.8125rem;
`

const Date = styled.div`
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.3125rem;
`

const CTA = styled(Button)`
  margin-top: 3.125rem;
`
